<template>
    <div class="menu">
        <div class="menu-logo">
            <img src="./../assets/img/logo_light.png" alt="">
            <div style="font-size: 25px;">MATHS PIE</div>
        </div>

        <div class="menu-title">
            <span>FROM</span>
            <a>www.mlsmsxp.com</a>
        </div>

        <div class="menu-content">
            <div class="ad-box">
                <div class="ad-box-item">
                    <img src="../assets/img/qrcode_cloud.png" alt="">
                </div>
                <div class="ad-box-item">
                    <img src="../assets/img/qrcode_edgehacker.png" alt="">
                </div>
            </div>
            <div class="ad-box">
                <div class="ad-box-item">
                    <img src="../assets/img/qrcode_cat.png" alt="">
                </div>
                <div class="ad-box-item">
                    <img src="../assets/img/qrcode_livehelper.png" alt="">
                </div>
            </div>

            <a class="wrist-lab" href="https://txc.qq.com/products/497740" target="_blank">
                <span>秒数联盟数学派反馈空间</span>
                <svg t="1709561078014" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4254" width="10" height="10"><path d="M731.7 475.1L370.6 80.8c-19.7-21.5-53-22.9-74.4-3.3-21.5 19.7-22.9 53-3.3 74.4l328.6 358.8-328.3 359.8c-19.6 21.5-18.1 54.8 3.4 74.4 21.5 19.6 54.8 18.1 74.4-3.4l360.5-394.9c0.7-0.8 1.5-1.7 2.1-2.5 16.4-19.9 16.1-49.4-1.9-69z" fill="#ffffff" p-id="4255"></path></svg>
            </a>
        </div>

        <div class="bottom">
            <a href="https://beian.miit.gov.cn/">沪ICP备2023032135号-1</a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
           
        }
    },
    methods: {
        
    },
    mounted() {
        console.log("秒数联盟数学派")
    }
}
</script>
<style scoped>
.menu {
    width: 285px;
    display: flex;
    flex-direction: column;
    background-color: #151728;
    color: #FFFFFF;
    min-height: 600px;
}

.menu-logo {
    display: flex;
    align-items: center;
    height: 80px;
    font-size: 35px;
    justify-content: center;
}

.menu-logo img {
    width: 50px;
    height: 50px;
    display: block;
    margin-right: 10px;
}

.menu-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 15px;
    height: 40px;
    color: #4D516B;
    border-bottom: 1px solid #24273B;
    border-top: 1px solid #24273B;
}

.menu-content {
    flex: 1;
    border-bottom: 1px solid #24273B;
    overflow-y: scroll;
}

.ad-box {
    margin: 15px;
    width: 255px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}

.ad-box-item {
    width: 120px;
    height: 120px;
    background-color: #24273B;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.ad-box-item img {
    display: block;
    width: 90px;
    height: 90px;
    border-radius: 5px;
}

.wrist-lab {
    background-color: #24273B;
    padding: 0 15px;
    height: 45px;
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #9AB4EB;
    border-radius: 5px;
}

.wrist-lab:hover {
    background-color: #7490CB;
}

.wrist-lab svg {
    background-color: #4D516B;
    padding: 5px;
    border-radius: 10px;
}
.bottom {
    height: 40px; 
    padding: 0 15px;
    display: flex;
    align-items: center;
    background-color: #151728;
}
.bottom a {
    color: #4D516B;
}
</style>